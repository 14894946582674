<template>
  <div>
    <div :style="{padding:props.boxPaddingH+'px '+props.boxPaddingV+'px',background: props.bgColor, color: props.textColor,fontSize: props.fontSize+'px',fontWeight: props.fontWeight,textAlign: props.textAlign}" class='text-title'>{{props.text}}</div>
  </div>
</template>
<script>
export default {
  name: 'add-img',
  props: {
    props: {
      type: Object
    }
  },
  setup (props, { root }) {
    return {

    }
  }
}
</script>
<style lang="less" scoped>
  .text-title {
    width: 100%;
    background: #fff;
  }
</style>
